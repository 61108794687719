@import '../../../styles/customMediaQueries.css';
.brandpushTrustBadge {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  min-height: 180px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.brandpushLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.brandpushTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: var(--colorGrey100);
  }
  & > .brandpushTitle {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    letter-spacing: 8px;
    color: #0e0e0e;
    background-color: var(--colorWhite);
    position: relative;
    z-index: 1;
    top: -11px;
    padding: 0 24px;
  }
}
.brandpushLogoContainerItem {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: center;
  @media (--viewportSmall) {
    gap: 24px;
  }
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.brandpushLeaf {
  opacity: 0.3;
  height: 60px;

  @media (--viewportSmall) {
    height: 80px;
  }
  @media (--viewportMedium) {
    height: 100px;
  }
}

.mirrored {
  transform: scaleX(-1);
}

.brandpushNewsLogo {
  max-width: 60px;
  height: 40px;
  @media (--viewportSmall) {
    max-width: 80px;
    height: 60px;
  }
  @media (--viewportMedium) {
    height: 80px;
    max-width: 100px;
  }
}

.brandpushFooter {
  font-size: 13px;
  color: #717171;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  & > span {
    & > a {
      color: #717171;
      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }
}

.brandpushWerifiedIcon {
  width: 12px;
  margin-right: 3px;
}
