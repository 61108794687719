@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.whoAreYouSec {
  padding-top: 64px;
  & .sectionTitle {
    text-align: center;
    font-size: 24px;
    line-height: 120%;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey900);
    margin: 10px 0;
    padding: 0;
    @media (--viewportMedium) {
      font-size: 30px;
    }
  }
  & .whoAreYouContent {
    display: flex;
    flex-direction: column;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 24px auto;
    padding: 32px 0px 0px 0px;
    @media only screen and (max-width: 768px) {
      padding: 6px 32px;
    }

    @media (--viewportMedium) {
      flex-direction: row;
    }
    & .whoAreYouContentBlock {
      text-align: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0 32px;
      @media (--viewportMedium) {
        padding: 0 32px 32px;
      }
      & > svg {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }
      & > h3 {
        font-size: 18px;
        line-height: 120%;
        font-weight: var(--fontWeightSemiBold);
        color: var(--colorGrey900);
        margin: 0 0 10px 0;
        padding: 0;
        @media (--viewportMedium) {
          font-size: 20px;
        }
      }
      & > p {
        font-size: 15px;
        line-height: 150%;
        margin: 0;
        padding: 0;
        width: 70%;
        font-weight: var(--fontWeightRegular);
        color: var(--colorGrey600);
        @media (--viewportMedium) {
          font-size: 16px;
        }
      }
      & > button {
        margin: 24px 0 0 0;
        height: auto;
        min-height: auto;
        width: auto;
        padding: 10px 24px;
        background-color: var(--colorWhite);
        border-radius: 100px;
        border: solid 1px var(--colorGrey400);
        color: var(--colorGrey800);
      }
    }
  }
}
