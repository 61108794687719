@import '../../../../styles/customMediaQueries.css';
.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 0 24px;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    & > header {
      & > h1 {
        text-align: center;
        font-weight: 400;
        font-size: 44px;
        margin-bottom: 28px;
        line-height: 110%;

        @media (--viewportMedium) {
          font-size: 64px;
        }
      }
    }
  }
}
.landingSearchForm {
  display: flex;
  max-width: 532px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  gap: 10px;
  @media (--viewportMedium) {
    gap: 0;
    background-color: var(--colorWhite);
    border-radius: 100px;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
  }
}
